import { useEffect } from 'react'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import type { AppProps } from 'next/app'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { init as initApm } from '@elastic/apm-rum'
import { disableFragmentWarnings } from 'graphql-tag'

import versionData from '@/data/version.json'
import { dataLayerVirtualPageViewEvent } from '@/utils/functions/dataLayerEvents'

import '../styles/tailwind.css'
import '../styles/nprogress.css'

const TopProgressBar = dynamic(
  () => {
    return import('@/molecules/TopProgressBar/TopProgressBar')
  },
  { ssr: false }
)

const queryClient = new QueryClient()

disableFragmentWarnings()

// query params to enable custom gtm environment
const gtmQueryParam = new URLSearchParams()
// these env should be only available on dev environment
if (
  process.env.NEXT_PUBLIC_GTM_AUTH_TOKEN &&
  process.env.NEXT_PUBLIC_GTM_PREVIEW_TOKEN
) {
  gtmQueryParam.set('gtm_auth', process.env.NEXT_PUBLIC_GTM_AUTH_TOKEN)
  gtmQueryParam.set('gtm_preview', process.env.NEXT_PUBLIC_GTM_PREVIEW_TOKEN)
  gtmQueryParam.set('gtm_cookies_win', 'x')
}
const gtmQueryString = gtmQueryParam.toString()
  ? `&${gtmQueryParam.toString()}`
  : ''

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  initApm({
    serviceName: process.env.NEXT_PUBLIC_APM_SERVICE_NAME,
    serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
    serviceVersion: versionData.version,
    environment: process.env.NEXT_PUBLIC_APM_ENV,
    active: Boolean(process.env.NEXT_PUBLIC_APM_ACTIVE)
  })

  useEffect(() => {
    dataLayerVirtualPageViewEvent()
  }, [router.pathname])

  return (
    <div data-version={versionData.version}>
      {process.env.NEXT_PUBLIC_DISABLE_EXTERNAL_SCRIPTS !== 'true' && (
        <>
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmQueryString}';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', 'GTM-K5X2GG3');
      `
            }}
          />
        </>
      )}

      <QueryClientProvider client={queryClient}>
        <TopProgressBar />
        <Component {...pageProps} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  )
}

export default MyApp
